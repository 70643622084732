<template>
  
  <div class="home" onload=greet()>
    <div class="P1Landing">
      <img id="P1" src="https://vault117homelab.blob.core.windows.net/nightcitylab/source-images-main/HeaderTorn1.png"
      style="width: 180%; height: 100%; object-fit: cover;">
    </div>
    <div class="P2Landing">
      <img id="P2" src="https://vault117homelab.blob.core.windows.net/nightcitylab/source-images-main/HeaderTorn2.png"
      style="width: 175%; height: 100%; object-fit: cover;">
    </div>
    <div class="P3Landing">
      <img id="P3" src="https://vault117homelab.blob.core.windows.net/nightcitylab/source-images-main/HeaderTorn3.png"
      style="width: 150%; height: 100%; object-fit: cover;">
    </div>
    <div class="P4Landing">
      <img id="P4" src="https://vault117homelab.blob.core.windows.net/nightcitylab/source-images-main/HeaderTorn4_V5.png"
      style="width: 105%; height: auto; object-fit: cover;">
    </div>
    <div class="P5Landing">
      <img id="P5" src="https://vault117homelab.blob.core.windows.net/nightcitylab/source-images-main/HeaderTorn5.png"
      style="width: 110%; height: 100%; object-fit: cover; transform: rotate(-295deg);">
    </div>
    <div class="P6Landing">
      <img id="P6" src="https://vault117homelab.blob.core.windows.net/nightcitylab/source-images-main/HeaderTorn6.png"
      style="width: 30%; height: 100%; object-fit: cover; transform: rotate(-295deg);">
    </div>
    <div class="P7Landing">
      <img id="P7" src="https://vault117homelab.blob.core.windows.net/nightcitylab/source-images-main/HeaderTorn7.png"
      style="width: 30%; height: 100%; object-fit: cover;">
    </div>
  <div class="LandingContent">
    <h1>Night City Lab</h1>
  </div>

  </div>
  
</template>

<script>
// Cloud Functions Related
import {SeeContainers} from '../CLD_RLTD/Azure.js'

export default {
  name: 'HomeView',
  metaInfo: {
      title: 'NightCityLab',
      titleTemplate: '%s | NightCityLab'
    },
    methods:{
    mounted() {
       SeeContainers()
    },
    greet() {
      alert('Hello, Vue.js!');
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h1{
  font-family: Better Font;
  color: white;
  font-size: 15rem;
}
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
  line-height: 300%;

  font-family: Better Font;
  color: white;
  font-size: 4rem;
}
a {
  color: #42b983;
}
.home{
  min-width: 1920px;
  min-height: 1080px;
  max-width: 3840px;
  max-height: 2160px;
}
.P1Landing{
  position: absolute;
  left: 0%;
  width: 25%;
  top: 0%;
}
.P2Landing{
  position: absolute;
  left: 0%;
  width: 25%;
  bottom: 0%;
}
.P3Landing{
  position: absolute;
  left: 40%;
  width: 25%;
  bottom: 0%;
}
.P4Landing{
  position: absolute;
  right: 1.0%;
  width: 25%;
  bottom: 0%;
}
.P5Landing{
  position: absolute;
  right: 45%;
  width: 25%;
  bottom: 40%;
}
.P6Landing{
  position: absolute;
  right: 40%;
  width: 25%;
  bottom: 0%;
}
.P7Landing{
  position: absolute;
  right: 15%;
  width: 25%;
  bottom: 40%;
}
.LandingContent {
  position: absolute;
  right: 37%;
  width: 20%;
  top: 10%;
}
::-webkit-scrollbar{
  width:5px;
  background-color:#000;
}
::-webkit-scrollbar-thumb{
  background: transparent;
}
</style>
