var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"OuterHeaven"},[_vm._m(0),_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_c('div',{staticClass:"LandingContent"},[_vm._m(5),_c('vs-button',{staticStyle:{"left":"20%"},attrs:{"active":_vm.active == 0,"block":"","color":"#000000","size":"xl","href":"https://github.com/MkhuseliNgetu/OuterHeaven"},on:{"click":function($event){_vm.active = 0}}},[[_c('i',{staticClass:"bx bx-shopping-bag",attrs:{"color":"#FFFFFFF"}}),_vm._v("  Shop at OuterHeaven ")]],2)],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"OuterHeavenLandingOne"},[_c('img',{attrs:{"id":"OuterHeavenLandingImageOne","src":"https://vault117homelab.blob.core.windows.net/nightcitylab/source-images-outerheaven/HeaderWomanOne.png"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"OuterHeavenLandingTwo"},[_c('img',{attrs:{"id":"OuterHeavenLandingImageTwo","src":"https://vault117homelab.blob.core.windows.net/nightcitylab/source-images-outerheaven/HeaderManOne.png"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"OuterHeavenLandingThree"},[_c('img',{attrs:{"id":"OuterHeavenLandingImageThree","src":"https://vault117homelab.blob.core.windows.net/nightcitylab/source-images-outerheaven/HeaderManTwo.png"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"OuterHeavenLandingFour"},[_c('img',{attrs:{"id":"OuterHeavenLandingImageFour","src":"https://vault117homelab.blob.core.windows.net/nightcitylab/source-images-outerheaven/HeaderWomanTwo.png"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"OuterHeavenLandingFive"},[_c('img',{attrs:{"id":"OuterHeavenLandingImageFive","src":"https://vault117homelab.blob.core.windows.net/nightcitylab/source-images-outerheaven/HeaderWomanThree.png"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('h1',[_vm._v("Outer"),_c('br'),_vm._v("Heaven")])
}]

export { render, staticRenderFns }