import Vue from 'vue'
import App from './App.vue'

// UI Related
import Vuesax from 'vuesax'
import 'vuesax/dist/vuesax.css'
import VueCookies from 'vue-cookies'
import VueMeta from 'vue-meta'

// Database & Hosting Related
import { initializeApp } from "firebase/app";

Vue.use(Vuesax)
Vue.use(require('vue-cookies'))
Vue.use(VueCookies, { expires: '1h', path: '/', domain: 'nightcitylab.co.za', secure: '', sameSite: 'Lax'})
Vue.use(VueMeta)

Vue.config.productionTip = false

const FBConfig ={

  apiKey: process.env.FIREBASE_API_KEY,

  authDomain: "nightcitylab.firebaseapp.com",

  projectId: "nightcitylab",

  storageBucket: "nightcitylab.appspot.com",

  messagingSenderId: "212001794308",

  appId: process.env.FIREBASE_MESSAGE_APP_ID,

  measurementId: process.env.FIREBASE_MEASUREMENT_ID

};

const app = initializeApp(FBConfig);

Vue.use(app)

new Vue({
  render: h => h(App),
}).$mount('#app')


