<template>
    <div class="header">
    <div class="center examplex">
      <vs-navbar center-collapsed style="background-color: transparent; font-size: x-large;">
        <!--Left side of navigation bar-->
        <template #left>
          <vs-button @click="activeSidebar = !activeSidebar" flat icon>
            <i class='bx bx-menu' style='color:#ffffff'  ></i>
          </vs-button>
          <p class="titlefont">Night City Lab</p>
        </template>
          <!--Center side of navigation bar-->
        <vs-navbar-item :active="active == 'OuterHeaven'" id="OuterHeaven">
          <p class="outerheavenfont"><a href="OuterHeaven.vue"></a> OuterHeaven</p>
        </vs-navbar-item>
        <vs-navbar-item :active="active == 'OperationNocturneOPN313'" id="OperationNocturneOPN313">
          <p class="operationnocturnefont"><a href="OperationNocturneOPN313.vue"></a>Operation Nocuturne</p>
        </vs-navbar-item>
      </vs-navbar>


      <vs-sidebar absolute v-model="active" background="dark" textWhite left :open.sync="activeSidebar" >
        <template #logo>
            <img src="../assets/logo.png">
        </template>

        <vs-sidebar-item id="EskomScheduleUpdate" v-on:click="GetEskomSchedule()">
        <template #icon>
            <i class='bx bxs-calendar'></i>
        </template>
        Update Schedule
        </vs-sidebar-item>

        <vs-sidebar-item id="NightCityDashboard" v-on:click="GetGrafanaDashboard()" href="https://snapshots.raintank.io/dashboard/snapshot/z2tOnWaTV3i2GILKXNlGXvg7oKfssPW1">
        <template #icon >
            <i class='bx bxs-dashboard'></i>
        </template>
        Dashboard
        </vs-sidebar-item>

        <vs-sidebar-item id="Portifolo" href="https://github.com/MkhuseliNgetu">
        <template #icon>
            <i class='bx bxl-github'></i>
        </template>
        GitHub
        </vs-sidebar-item>

        <vs-sidebar-item id="SocialMedia" href="https://www.linkedin.com/in/mkhuseli-ngetu-137174284/"> 
        <template #icon >
            <i class='bx bxl-linkedin'></i>
        </template>
        Linkedin
        </vs-sidebar-item>

        <vs-sidebar-item id="PrivateDashboard" v-on:click="GetAuth()" href="https://yorha-automation-01.web.app/api/auth/login"> 
        <template #icon >
            <i class='bx bx-log-in-circle'></i>
        </template>
        Login
        </vs-sidebar-item>

        <!-- Footer related -->
        <template #footer>
            <vs-row justify="space-between">
                <vs-avatar dark>
                    <i class='bx bx-user-circle' style='color:#ffffff'  ></i>
                </vs-avatar>

                <vs-avatar dark badge-color="danger" badge-position="top-right">
                <i class='bx bx-bell' style='color:#ffffff' ></i>

                <template #badge>
                    {{notifications}}
                </template>
                </vs-avatar>
            </vs-row>
        </template>

        </vs-sidebar>

      
  </div>
</div>


</template>

<script>
import VueCookies from 'vue-cookies'
export default {
  name: 'HeaderView',
  data:() => ({
        active: 'home',
        notifications: 0,
        activeSidebar: false,
        isHidden: false,
        Dashboards: null,
        key: "Schedule out of date.",
        hidden: true,
  }),
  methods: {
    async GetEskomSchedule() {
      this.Dashboards = await fetch('https://nightcitylabbackend-e5lghbasoa-bq.a.run.app/Update').then(res => res.json())
      .then(data => this.key = data.schedule)
      .catch(err => console.log(err.schedule))
      this.key = await this.key.replace('/r','/')

      VueCookies.set('LoadSheddinng Schedule', this.key, "1h")
    },
    GetGrafanaDashboard(){
    },
    GetAuth(){
    }
}
}
</script>
<style scoped>
p{

  display: inline-block;
  margin-left: 40px;
}
.titlefont{
  font-size: 2.5rem;
  font-family: Better Font;
  color: white;
}
.outerheavenfont{
  font-size: 1.5rem;
  font-family: Porter Sans Block;
  color: white;
}
.operationnocturnefont{
  font-size: 1.5rem;
  font-family: Cyberpunk;
  color: white;
}
label{
    font-size: 2.5rem;
    color: white;
    font-family: Better Font;
    text-align: center;
}
</style>