//Blob Related
const { BlobServiceClient } = require("@azure/storage-blob");

const account = process.env.AZURE_ACCOUNT_NAME;
const sas = process.env.AZURE_SAS;

const blobServiceClient = new BlobServiceClient(`https://${account}.blob.core.windows.net${sas}`);

export const SeeContainers = async ()=>{
  
   console.log(blobServiceClient.listContainers())
}



